// import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import CtaSlim from '../CtaSlim';
import Button from '../Button/Button';
import Logo from '../Logo/Logo';
import MarqueeText from '../MarqueeText';

import styles from './Footer.module.scss';

const Footer = ({
    buttons = [],
    socials = [],
    text = '',
    links = [],
    copyright = '',
    marqueeText = null,
    ctaSlim = null,
    isErrorPage = false,
}) => {
    const { t } = useTranslation();

    const handleCookieConsent = () => {
        if (typeof CookieConsent !== 'undefined') {
            CookieConsent.renew();
        }
    };

    return (
        <>
            <footer className={styles['Footer']}>
                {!!ctaSlim && <CtaSlim {...ctaSlim} />}
                {!!marqueeText && !isErrorPage && (
                    <MarqueeText {...marqueeText} />
                )}
                <div className={styles['Footer__Container']}>
                    <div className={styles['Footer__Inner']}>
                        <div className={styles['Footer__Top']}>
                            <div className={styles['Footer__Column']}>
                                <h2 className={styles['Footer__Title']}>
                                    {'Ladda ner'}
                                </h2>
                                <ul className={styles['Footer__ButtonList']}>
                                    {buttons.map((item, index) => (
                                        <li
                                            key={index}
                                            className={styles['Footer__Item']}>
                                            <Button
                                                icon={item.icon}
                                                link={item.link}
                                                modifiers={['quaternary']}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className={styles['Footer__TopRight']}>
                                <div className={styles['Footer__Column']}>
                                    <h2 className={styles['Footer__Title']}>
                                        {'Följ oss'}
                                    </h2>
                                    <ul className={styles['Footer__List']}>
                                        {socials.map((item, index) => (
                                            <li
                                                key={index}
                                                className={
                                                    styles['Footer__SocialItem']
                                                }>
                                                <a
                                                    className={
                                                        styles[
                                                            'Footer__SocialLink'
                                                        ]
                                                    }
                                                    href={item.link.href}
                                                    target={item.link.target}>
                                                    {item.link.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={styles['Footer__Column']}>
                                    <div className={styles['Footer__Logo']}>
                                        <Logo
                                            dimensions={{
                                                width: '120px',
                                                height: '40px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className={styles['Footer__Text']}>{text}</p>
                        <div className={styles['Footer__Bottom']}>
                            <div className={styles['Footer__Column']}>
                                <ul className={styles['Footer__LinkList']}>
                                    {links.map((item, index) => (
                                        <li
                                            key={index}
                                            className={
                                                styles['Footer__LinkItem']
                                            }>
                                            <a
                                                className={
                                                    styles['Footer__Link']
                                                }
                                                href={item.link.href}
                                                target={item.link.target}>
                                                {item.link.title}
                                            </a>
                                        </li>
                                    ))}
                                    {!isErrorPage && (
                                        <li
                                            className={
                                                styles['Footer__LinkItem']
                                            }>
                                            <button
                                                className={
                                                    styles[
                                                        'Footer__ConsentButton'
                                                    ]
                                                }
                                                onClick={() =>
                                                    handleCookieConsent()
                                                }>
                                                {t('Footer.cookieSettings')}
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className={styles['Footer__Column']}>
                                <p className={styles['Footer__Copyright']}>
                                    {copyright}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

Footer.propTypes = {};

export default Footer;
